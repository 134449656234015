import React from 'react'
import { useTranslation } from 'react-i18next'
import { TextField, Button, FormControl } from '@mui/material'
import AllInputProps from '../utils/inputProps'
import { currentLanguages } from '../i18n/config/currentLanguages'

const ContactForm = () => {
    const { t, i18n } = useTranslation('contact')
    const currentLangPath = currentLanguages.find(lang => lang.shorthand === i18n.language).path

    return (
        <div className="contactPage" id="contact">
            <h2 className="pageTitle">{t('header')}</h2>
            <hr />
            <div className="contactContents">
                <p>{t('paragraph')}</p>
                <FormControl
                    component="form"
                    name="Contact"
                    method="post"
                    className="contactForm"
                    data-netlify="true"
                    data-netlify-honeypot="bot-field"
                    action={`${currentLangPath}/thank-you`}
                >
                    {/* Need to have this hidden input with the form name to your JSX form */}
                    <input type="hidden" name="form-name" value="Contact" />
                    <TextField
                        fullWidth
                        required
                        id="contactForm-name"
                        name="Name"
                        label={t('name')}
                        variant="filled"
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...AllInputProps}
                    />
                    <TextField
                        fullWidth
                        required
                        id="contactForm-email"
                        type="email"
                        name="Email"
                        label={t('email')}
                        variant="filled"
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...AllInputProps}
                    />
                    <TextField
                        fullWidth
                        id="contactForm-company"
                        name="Company"
                        label={t('company')}
                        variant="filled"
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...AllInputProps}
                    />
                    <TextField
                        fullWidth
                        required
                        id="contactForm-message"
                        label={t('message')}
                        name="Message"
                        multiline
                        rows={4}
                        variant="filled"
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...AllInputProps}
                    />
                    <Button
                        type="submit"
                        variant="contained"
                        size="large"
                        disableElevation
                        className="sendButton"
                    >
                        {t('send')}
                    </Button>
                </FormControl>
            </div>
        </div>
    )
}

export default ContactForm
